import React from "react";
import FieldVisitList from "./List";

const FieldVisit = () => {
  return (
    <>
      <FieldVisitList />
    </>
  );
};

export default FieldVisit;
