export const RouteUrls = {
  createUrl: "/create",
  editUrl: "/edit",
  viewUrl: "/view",
  signInUrl: "/",
  petitionListUrl: "/petitions",
  fieldVisitUrl: "/field-visit",
  letterUrl: "/letter",
  listUrl: "/list",
  finalResponseUrl: "/final-response",
  letterEditorUrl: "/letter-edit",
  dashboardUrl: "/dashboard",
  reportUrl: "/report",
};
