import React from "react";
import LetterList from "./List";

const Letter = () => {
  return (
    <>
      <LetterList />
    </>
  );
};

export default Letter;
