import React from "react";
import { Grid } from "@mui/material";
import List from "./List";

const Petitions = () => {
  return (
    <Grid>
      <List />
    </Grid>
  );
};

export default Petitions;
